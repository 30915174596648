import { TrackingService } from './tracking-service';

if ((window as WindowSSS).TrackingService) {
  (window as WindowSSS).TrackingService = TrackingService;
  (window as WindowSSS).TrackingService.init();
  (window as WindowSSS).TrackingService.send(
    null,
    'load-page',
    {},
    {
      pageName: window.location.href,
      prevPage: document.referrer,
    },
  );
}
