
const push = (event: TrackingObject): void => (window as WindowSSS).dataLayer.push({
  event: event.eventName,
  type: event.domEvent.type,
  domEvent: event.domEvent,
  context: event.trackingContext,
  data: event.trackingData,
  ...(Array.from(
    event.domEvent.target
      ? (event.domEvent.target as HTMLElement).attributes
      : [],
  ) as Attr[])
    .filter(({ name }) => name !== 'data-resources')
    .reduce(
      (accumulator, { name, nodeValue }) => ({
        ...accumulator,
        [name]: nodeValue,
      }),
      [],
    ),
});

// eslint-disable-next-line no-prototype-builtins
const isTrackingLoaded = (): boolean => (window as WindowSSS).hasOwnProperty('sss')
// eslint-disable-next-line no-prototype-builtins
  && (window as WindowSSS).sss.hasOwnProperty('datalayer');

const addTrackingObject = (): void => {
  (window as WindowSSS).sss = (window as WindowSSS).sss || {};
  (window as WindowSSS).sss.datalayer = { push };
};

export const TrackingService: TrackingService = {
  isTrackingLoaded,

  init(): void {
    if (!isTrackingLoaded()) {
      addTrackingObject();
    }
  },

  send: (
    domEvent: Event,
    eventName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trackingContext: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trackingData: any,
  ): Promise<void> => new Promise<void>((resolve): void => {
    (window as WindowSSS).sss.datalayer.push({
      trackingContext,
      trackingData,
      domEvent,
      eventName,
    });
    resolve();
  }),
};
